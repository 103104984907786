import { Container, Table, Row, Col } from "react-bootstrap";

export const SubSizeTable = (props) => {

    var ipRange = [...Array(23).keys()].reverse().map(k => k + 8);

    const intToDot = (intVal) => {
        let remain = BigInt(intVal);
        let octs = [...Array(4).keys()].map(k => {
            let q = remain % 256n;
            remain = remain >> 8n;
            return q;
        });
        return `${octs[3]}.${octs[2]}.${octs[1]}.${octs[0]}`;
    }

    const rowIsMask = (r) => {
        if (props.ipv6) {
            if (props.mask > 32) {
                return r === props.mask ? 'bordered' : "";
            } else {
                return "";
            }
        }
        return r === props.mask ? 'bordered' : '';
    }

    return (
        <Container className="p-5">
            <h3 id="subtable" className="inline">Subnet Sizing Table</h3>
            <div className="text-dark">subnet cheat sheet for typical assignments</div>
            <Row>
                <Col>
                    <Table striped bordered variant="light" size="sm">
                        <thead>
                            <tr>
                                <th>IPv4</th>
                                <th>Mask</th>
                                <th>Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ipRange.map(r => (
                                <tr key={r} className={rowIsMask(r)}>
                                    <td>/{r}</td>
                                    <td>{intToDot(2 ** 32 - 2 ** (32 - r))}</td>
                                    <td>{2 ** (32 - r)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table striped bordered variant="light" size="sm">
                        <thead>
                            <tr>
                                <th>IPv6</th>
                                <th>n x /64's</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ipRange.map(r => (
                                <tr key={r} className={rowIsMask(r + 34)}>
                                    <td>/{r + 34}</td>
                                    <td>{2 ** (30 - r)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table striped bordered variant="light" size="sm">
                        <thead>
                            <tr>
                                <th>Decimal</th>
                                <th>Binary</th>
                                <th>Hex</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ipRange.filter(r => r>14).map(r => (
                                <tr key={r} className="font-monospace">
                                    <td>{30-r}</td>
                                    <td>{('0000'+(30-r).toString(2)).slice(-4)}</td>
                                    <td>{(30-r).toString(16)}</td>
                                </tr>
                            ))}
                            <tr><td colSpan={3}>IPv4: multiple the top nibble by 16 then add low nibble, where a nibble is 4 bits</td></tr>
                            <tr><td colSpan={3}>example 1010.1000 = 10 X 16 + 8 = 168 </td></tr>
                            <tr><td colSpan={3}>To convert from decimal to binary, divide by 16. ie: 168 / 16 = 10, remainder 8 </td></tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );

}